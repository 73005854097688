import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import ThemeContext from "./ThemeContext";

export default function ThemeProvider({ defaultTheme = "light", children }) {
  const [theme, setTheme] = useState(() => localStorage.getItem("theme") ?? defaultTheme);

  // We can use applyTheme to set a theme persistently, setTheme to set a theme per page but not be persistant
  const applyTheme = (newTheme) => {
    document.querySelector("body")?.classList.replace(theme, newTheme);
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
  };

  useEffect(() => {
    document
      .querySelector("body")
      ?.classList.add(theme, "text-foreground", "bg-background", "font-local");
  }, []);

  const themeContextProviderValue = useMemo(
    () => ({ theme, setTheme, applyTheme }),
    [theme, setTheme, applyTheme],
  );

  return (
    <ThemeContext.Provider value={themeContextProviderValue}>{children}</ThemeContext.Provider>
  );
}
ThemeProvider.propTypes = {
  defaultTheme: PropTypes.string,
  children: PropTypes.node.isRequired,
};
