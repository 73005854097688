import { useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "features/authentication";
import useScrollAmount from "features/layout/hooks/useScrollAmount";
import { NavigationConfigContext } from "features/navigation";
import { ErrorContext } from "features/report";
import { UserContext } from "features/user";

import { Drawer, ThemeContext } from "ui";

import NavbarDesktop from "./NavbarDesktop";
import NavbarMobile from "./NavbarMobile";
import OffcanvasAccount from "./OffcanvasAccount";
import OffcanvasSearch from "./OffcanvasSearch";

export default function ProspectNavbar({ icon = undefined, size = undefined }) {
  const [page, setPage] = useState(null);
  const { user } = useContext(UserContext);
  const { config } = useContext(NavigationConfigContext);
  const { showError } = useContext(ErrorContext);
  const { showAuth } = useContext(AuthContext);
  const { theme, applyTheme } = useContext(ThemeContext);
  const scrollReached = useScrollAmount(0.01);
  const navigate = useNavigate();

  const handleToggleOffcanvas = ({ isProtectedAction, page: newPage }) => {
    if (isProtectedAction && !user.doesSessionExist) {
      showAuth();
      return;
    }

    setPage((prev) => (newPage === prev ? null : newPage));
  };

  const handleNavigate = (to) => {
    setPage(null);
    navigate(to, {
      state: {
        referrer: "navbar",
      },
    });
  };

  const navbarConfig = useMemo(() => {
    const routes = config.navbar.routes({
      user,
      theme,
      toggleTheme: (v) => applyTheme(v),
      handleToggleOffcanvas,
      showError,
      navigate: handleNavigate,
    });

    return {
      size,
      mobileRoutes: routes?.mobileRoutes || [],
      desktopRoutes: routes?.desktopRoutes || [],
      desktopIcons: routes?.desktopIconRoutes || [],
      offcanvas: routes?.offcanvasRoutes || [],
      homeLink: config.redirectHome,
      collapse: config.navbar?.collapse || "md",
      icon: !scrollReached ? icon || config.navbar?.icon[theme] : config.navbar?.icon[theme],
      onClose: () => setPage(null),
    };
  }, [user, page, scrollReached, theme]);

  return (
    <>
      <NavbarDesktop {...navbarConfig} />
      <NavbarMobile {...navbarConfig} />

      <Drawer
        isOpen={page === "account"}
        onClose={() => setPage(null)}
        className="mb-16 md:mb-0 h-full relative flex justify-between flex-col py-8 md:py-16 px-8 sm:px-16"
      >
        <OffcanvasAccount {...navbarConfig} />
      </Drawer>

      <Drawer
        isOpen={page === "search"}
        onClose={() => setPage(null)}
        className="mb-16 md:mb-0 h-full relative flex justify-between flex-col p-0 pb-8 md:pb-16"
      >
        <OffcanvasSearch {...navbarConfig} />
      </Drawer>
    </>
  );
}
ProspectNavbar.propTypes = {
  icon: PropTypes.string,
  size: PropTypes.string,
};
