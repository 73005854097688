import { Skeleton } from "@nextui-org/react";
import styled from "styled-components";
import { twMerge } from "tailwind-merge";

const StyledSkeleton = styled(Skeleton).attrs((props) => ({
  className: twMerge(
    "bg-default-200 before:via-default-300 before:border-default-300/30",
    props.className,
  ),
}))``;

export default StyledSkeleton;
