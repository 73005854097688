import { useRef } from "react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

import useScroll from "hooks/useScroll";

const swiper = tv({
  slots: {
    base: "relative my-2 flex items-center h-full",
    wrapper: "flex w-full flex-nowrap px-6 gap-x-5 overflow-x-auto scrollbar-hide h-full",
    leftGradient:
      "absolute flex h-full inset-y-0 bg-gradient-to-r to-transparent transition-opacity duration-150 from-zinc-800",
    rightGradient:
      "absolute flex h-full inset-y-0 right-0 bg-gradient-to-l to-transparent transition-opacity duration-150 from-zinc-800",
    button:
      "touch-device:invisible mx-3 self-center rounded-full flex justify-center items-center bg-background bg-opacity-90 drop-shadow",
  },
});

export default function Swiper({ className = "", classNames = {}, children }) {
  const scrollRef = useRef(null);
  const { atEnd, atStart, scrollRight, scrollLeft } = useScroll(scrollRef);
  const { base, wrapper, leftGradient, rightGradient, button } = swiper();

  return (
    <div className={twMerge(base(), className, classNames.base)}>
      <div className={twMerge(wrapper(), classNames.wrapper)} ref={scrollRef}>
        {children}
      </div>
      <div className={twMerge(leftGradient(), atStart && "opacity-0", classNames.leftGradient)}>
        <div
          role="button"
          tabIndex={0}
          className={twMerge(button(), classNames.button)}
          onClick={(e) => {
            e.stopPropagation();
            scrollLeft();
          }}
        >
          <ChevronLeft fontSize="large" className="text-foreground" />
        </div>
      </div>
      <div className={twMerge(rightGradient(), atEnd && "opacity-0", classNames.rightGradient)}>
        <div
          role="button"
          tabIndex={0}
          className={twMerge(button(), classNames.button)}
          onClick={(e) => {
            e.stopPropagation();
            scrollRight();
          }}
        >
          <ChevronRight fontSize="large" className="text-foreground" />
        </div>
      </div>
    </div>
  );
}

Swiper.propTypes = {
  className: PropTypes.string,
  classNames: PropTypes.shape({
    base: PropTypes.string,
    wrapper: PropTypes.string,
    leftGradient: PropTypes.string,
    rightGradient: PropTypes.string,
    button: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
};
